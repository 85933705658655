export type ConfigType = {
  routes: {
    home: string;
    register: string;
    registerConfirm: string;
    signIn: string;
    recoverPassword: string;
    myMedicalRecords: string;
    shared: string;
    release: string;
    request: string;
    studyId: string;
    patients: string;
    patientRecords: string;
    releaseHistory: string;
    requestHistory: string;
    requestHistoryFromId: string;
    external: string;
    accessPdf: string;
    termsAndConditions: string;
    privacyPolicy: string;
    cookiePolicy: string;
    notFound: string;
    settings: string;
    sessionTimeout: string;
    authorizations: string;
  };
  api: {
    baseUrl: string;
    loginPath: string;
    loginRequest: string;
    accessLoginPath: string;
    dicom: string;
    dicomId: string;
    dicomIdIpfs: string;
    dicomIdMint: string;
    dicomIdDownload: string;
    dicomSharedWithMe: string;
    userId: string;
    study: string;
    studyPrevalidate: string;
    studyUpload: string;
    studyRelease: string;
    studyId: string;
    studyIdShare: string;
    studySharedWithMe: string;
    studyIdRevoke: string;
    studyIdFiles: string;
    studyShares: string;
    studiesAll: string;
    studyIdFileDownload: string;
    studyIdFileDownloadSize: string;
    providerPatients: string;
    providerPatientId: string;
    providerPatientStudies: string;
    accessRequest: string;
    accessRequestHash: string;
    accessStudy: string;
    accessReleaseStudy: string;
    accessStudyAll: string;
    accessStudyById: string;
    userActivity: string;
    register: string;
    confirmRegister: string;
    registerConfirmProvider: string;
    registerResend: string;
    userMe: string;
    userValidate: string;
    importStudiesEmail: string;
  };
  customization: {
    maxStudySizeInGb: number;
    maxFileSizeInMb: number;
    axiosDefaultTimeout: number;
    axiosStudyUploadTimeout: number;
    studiesPerPage: number;
    filesPerPage: number;
    sharesPerPage: number;
    patientsPerPage: number;
    historyStudiesPerPage: number;
    autoRefreshTimerInSeconds: number;
    activitiesPerPage: number;
    inactivityTimeoutMs: number;
    userDataRefreshTimerMs: number;
  };
  support: {
    supportEmail: string;
    externalRelease: string;
  }
};

const config: ConfigType = {
  routes: {
    home: '/',
    register: '/register',
    registerConfirm: '/confirm-registration',
    signIn: '/signin',
    recoverPassword: '/recover-password',
    myMedicalRecords: '/my-medical-records',
    shared: '/shared',
    release: '/release',
    request: '/request-record',
    studyId: '/study/:id',
    patients: '/patients',
    patientRecords: '/patients/:id',
    releaseHistory: '/history/release',
    requestHistory: '/history/request',
    requestHistoryFromId: '/history/request/:id',
    external: '/external',
    accessPdf: '/access-pdf',
    termsAndConditions: '/terms-and-conditions',
    privacyPolicy: '/privacy-policy',
    cookiePolicy: '/cookie-policy',
    notFound: '/404',
    settings: '/settings',
    sessionTimeout: '/session-timeout',
    authorizations: '/authorizations',
  },
  api: {
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    loginPath: '/auth/login',
    loginRequest: '/auth/request-login',
    accessLoginPath: '/auth/access',
    dicom: '/dicom',
    dicomId: '/dicom/:id',
    dicomIdIpfs: '/dicom/:id/ipfs',
    dicomIdMint: '/dicom/:id/mint',
    dicomIdDownload: '/dicom/:id/download',
    dicomSharedWithMe: '/dicom/shared-with-me',
    userId: '/user/:id',
    study: '/study',
    studyPrevalidate: '/study/prevalidate',
    studyUpload: '/study/upload',
    studyRelease: '/study/release',
    studyId: '/study/:id',
    studyIdFiles: '/study/:id/files',
    studyShares: '/study/:id/shares',
    studyIdShare: '/study/share/:id',
    studySharedWithMe: '/study/shared-with-me',
    studyIdRevoke: '/study/revoke/:id',
    studiesAll: '/provider/studies',
    studyIdFileDownload: '/study/files/:id/download',
    studyIdFileDownloadSize: '/study/files/:id/size',
    providerPatients: '/provider/patients',
    providerPatientId: '/provider/patients/:id',
    providerPatientStudies: '/provider/patients/:id/studies',
    accessRequest: '/access',
    accessRequestHash: '/access?hash=:hash',
    accessStudy: '/access/study',
    accessReleaseStudy: '/access/study-v2',
    accessStudyAll: '/access/studies',
    accessStudyById: '/access/:id',
    userActivity: '/user/me/activity',
    register: '/auth/register',
    confirmRegister: '/auth/confirm',
    registerConfirmProvider: '/auth/confirm/provider',
    registerResend: '/auth/register/resend',
    userMe: '/user/me',
    userValidate: '/user/validate',
    importStudiesEmail: '/user/import-studies-email',
  },
  customization: {
    maxStudySizeInGb: 5,
    maxFileSizeInMb: 1024,
    axiosDefaultTimeout: 30000,
    axiosStudyUploadTimeout: 600000,
    studiesPerPage: 6,
    filesPerPage: 6,
    sharesPerPage: 6,
    patientsPerPage: 10,
    historyStudiesPerPage: 10,
    autoRefreshTimerInSeconds: 10,
    activitiesPerPage: 6,
    inactivityTimeoutMs: 900000,
    userDataRefreshTimerMs: 2000,
  },
  support: {
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || "",
    externalRelease: process.env.REACT_APP_EXTERNAL_RELEASE || 'https://app.medimint.health/external',
  }
};

export default config;
